<template>
  <div class="commissionAgreement_page">
    <p class="title">酷联盟成员佣金与行为规则</p>
    <p class="title1">第一条【目的和依据】</p>
    <p>1.1 为明确酷联盟成员的提成计算方式，保障成员的合法权益，以及进一步规范成员行为，根据《酷联盟服务协议》及酷联盟的相关规范的规定，制定本规则。 </p>
    <p>1.2 本规则为《酷联盟服务协议》的重要组成部分，成员同意《酷联盟服务协议》即为也同意本规则。若成员不同意本规则，则应停止使用酷联盟服务，继续使用则视为成员同意受本规则约束。</p>
    <p class="title1">第二条【成员行为准则和佣金计算原则】</p>
    <p class="indent">酷联盟成员从事会员卡推广销售的佣金的取得均应以同时满足以下原则为前提，凡不符合以下任一原则的均不能获得佣金。成员知悉并全部接受，对于成员销售推广行为是否违反行为规范以及违规后的处理方式，酷联盟有权独立判断和处理。</p>
    <p >2.1 真实有效购买原则：真实有效购买指酷联盟成员推荐的用户必须自愿、且有实际支付购优酷/酷喵VIP会员卡行为。用户虚假交易、非真实意愿购买、恶意刷单等行为，均不视为有效购买。</p>
    <p >2.2 真实有效二维码原则：用户购买会员卡时使用的通道应当为酷联盟直接提供给推广人的二维码，该二维码包含推广人的专属链接，推广人必须直接使用该二维码和专属链接继续推广，并保证其必须为真实有效的。若推广人通过非正当方式进入优酷系统或成员销售订单系伪造二维码/链接或使用其他非酷联盟提供给推广者的二维码/链接所产生的，则不计算佣金。</p>
    <p >2.3  不作弊原则：联盟成员进行推广售卡，必须遵守《酷联盟服务协议》、《活动规则》及平台相关规则的规定，正当合法推广指定产品，不得采取欺骗或作假等手段谋取不正当利益，若发现有作弊行为（包括但不限于恶意刷量、批量退费、篡改交易订单数据、利用系统bug或其他技术手段伪造交易数据等）的，酷联盟会根据《联盟成员服务协议》《活动规则》及平台不时制定的相关规范对成员采取相应处理措施，
      包括但不限于取消作弊人员的合作资格、部分全额扣除作弊人员佣金。因作弊行为或其他违约、侵权行为导致酷联盟遭受经济损失或用户投诉等问题的，成员还应负责解决并承担相应的赔偿责任。</p>
    <p >2.4 指定商品原则：可获得佣金的产品仅限于酷联盟网站明示符合提成条件的相关会员产品，用户在活动期望外，或购买其他非指定推广产品的不能获得佣金。</p>
    <p>2.5 结算原则：</p>
    <p>（1）酷联盟成员从事推广售卡活动所得的佣金均以人民币进行结算，以元为单位计数。 </p>
    <p><span>（2）最低满2元可提现，单次提现最高金额10000元，结算时需按个税等相关条例缴纳税费；</span></p>
    <p><span>（3）提现综合服务费：云账户按照固定比例收取综合服务费（含个税），综合服务费合计=佣金收入*7.2%；</span></p>
    <p>（4）如因用户违规，酷联盟有权拒绝支付所有未结算佣金。</p>
    <p>2.6 身份验证原则：</p>
    <p><span>（1）成员在注册酷联盟时需提供有效身份证信息，且用户需完成实名认证并确保三要素实名一致，提供本人名下银行卡账号后可进行结算；</span></p>
    <p>（2）如因用户提供信息错误，导致酷联盟委托的付款合作方无法扣税的，酷联盟有权暂停或和不予支付佣金；如因用户提供的信息、材料不真实、不合法、侵权导致的任何问题和责任，由用户自行解决与承担，与酷联盟运营方、优酷无关。</p>
    <p>2.7 维护市场秩序原则：成员与平台应共同维护推广对象的市场秩序，成员不得以任何形式扰乱推广会员产品的价格市场，不得以任何理由和借口向用户、其他成员收取任何费用，尤其是以酷联盟、优酷名义收取，否则按《酷联盟服务协议》约定的责任条款执行。</p>
    <p>第三条【推广佣金计算方式】</p>
    <p>3.1 酷联盟成员严格履行《 酷联盟服务协议》及《活动规则》及酷联盟相关规则且无违规、违约行为的前提下，使用酷联盟提供的服务开展推广会员卡活动可获得相应佣金。</p>
    <p class="indent"><span>推广人推广有效订单生效后，佣金将进入待结算状态；有效订单生效15个自然日后进入可提现状态，每月的10号和15号推广人可以对可提现状态下的金额发起提现申请。系统审核通过后，通常情况下，会在10个工作日内向推广人打款。</span></p>
    <p class="indent"><span>佣金支付由运营方西藏福禄网络科技有限公司负责，西藏福禄通过其合作方：灵时云（苏州）信息科技有限公司作为支付主体向推广人在酷联盟申报的收款账号打款。</span></p>
    <p class="indent"><span>您亦理解并同意酷联盟根据实际运营需要变更或新增合作方，实际变更和新增的公司以实际支付佣金结算款的汇款公司为准。 </span></p>
    <p class="indent"><span>若错过当月申请结算日期，当月佣金将自动累计至下次结算。若本服务关停，最长给推广人一个月时间提走全部推广费用，否则佣金清零后果由推广人自行承担。</span></p>
    <p>3.2 计入佣金有效订单范围：推广佣金为推广人通过活动页面直接推广商品的销售佣金。但以下订单为无效订单，不享受佣金：</p>
    <p>（1）自购订单；</p>
    <p>（2）自动续费产品除首次订购外的续费期订单；</p>
    <p>（3）未支付成功订单；</p>
    <p>（4）退款或被撤销订单；</p>
    <p>（5）转赠订单；</p>
    <p>（6）使用代金券订单；</p>
    <p>（7）非通过二维码/专属链接直接分享的付款订单； </p>
    <p>（8）推广链接格式不正确而导致不能正确跟踪的订单；</p>
    <p>（9）用户从酷联盟上的推广链接访问联盟网站，在推广链接活动有效期外或本活动结束后购买的订单；</p>
    <p>（10）用于再度销售或任何商业用途的订单；</p>
    <p>（11）其他系统判定的安全漏洞或系统盘点的作弊或黑灰产行为相关的订单。</p>
    <p>3.3 优酷/酷喵会员卡价格由优酷自行制定，各项推广产品佣金规则见推广页面说明。</p>
    <p>3.4 优酷/酷喵会员卡一经售出不予退换，若发生用户退款，相关退费所产生的成本由推广者自行承担，不计入佣金。如用户向优酷主张退款的，相关处理如下：退款订单均不返佣； 如每个结算周期退款数占其整体推广订单数超过一定比例（超过3%），或有虚假邀请好友，虚假交易行为，
      酷联盟有权直接判定该您存在作弊刷单行为，您的本结算周期佣金将不予结算，且酷联盟有权终止您的推广资格。如退费发生前已经向您结算佣金的，已经结算的部分您需全额退还。</p>
    <p class="title1">第四条【附则】</p>
    <p>4.1 酷联盟根据业务发展需要及市场情况，可能会随时对本规则进行调整，并在酷联盟服务平台上予以公告或通过其他形式通知成员。成员应及时查看更新后的规则，若不同意规则变更的，应及时停止使用本服务，若继续使用酷联盟服务的，视为成员同意变更后的规则。</p>
    <p>4.2 本规则中使用的名词和术语，本规则有定义的以本规则定义的为准，本规则未定义或未明确约定事项以《酷联盟服务协议》《活动规则》中约定为准，如均未规定的，以商业习惯及上下文的字面通常意义为准。</p>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
.commissionAgreement_page {
  min-height: 100vh;
  background: #f5f6f8;
  padding: 0.30rem;
  line-height: 18px;
  font-size:14px;
  color:#555;
  .title{
    text-align: center;
    font-size:16px;
    color:#000;
    font-weight: bold;
    margin-top: 0.30rem;
    margin-bottom: 0.60rem;
  }
  p{
    margin-top:0.05rem;
    span{
      font-weight: bold;
      color:#000;
    }
  }
  .title1{
    color:#000;
    font-weight: bold;
    margin-top:0.20rem;
  }
  .indent{
    text-indent:0.70rem;
  }
}
</style>
